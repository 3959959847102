<template>
  <div>
    <div v-if="editMode === false">
      <div class="stepbar" v-for="(step, index) in steps" :key="index">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="step" :class="step.css"></div>
          </template>
          <span>{{ step.nameTranslated }}</span>
        </v-tooltip>
      </div>

      <div class="step_text mt-1">
        <i>{{ $t(statusForm) }}</i>
      </div>
    </div>
    <div v-else>
      <div class="d-flex">
        <v-select
          @change="updateStatus()"
          dense
          :items="steps"
          label="Status"
          outlined
          v-model="statusForm"
          item-text="nameTranslated"
          item-value="name"
          :loading="loading"
          :disabled="loading"
        ></v-select>
        <v-tooltip top v-if="type === 'auscultation'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editModeFalse"
              class="ml-2 mb-1"
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>mdi-undo</v-icon>
            </v-btn>
          </template>
          <span>Retour</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CampaignStepBar",
  props: {
    currentStep: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    campaignId: {
      type: String,
      required: true
    },
    editMode: {
      type: Boolean,
      required: true
    },
    coringType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      steps: [],
      statusForm: null,
      loading: false,
      tempSteps: []
    };
  },
  methods: {
    async updateStatus() {
      this.loading = true;
      this.$api.auscultationCampaigns
        .editStatus(this.campaignId, this.statusForm)
        .then(() => {
          this.loading = false;
          this.loadSteps();
          this.editMode = false;
        })
        .catch(() => (this.loading = false));
      this.editModeFalse();
    },
    loadSteps() {
      this.statusForm = this.statusForm === null ? this.currentStep : this.statusForm;
      if (this.type == "auscultation") {
        this.tempSteps = [
          {
            name: "preparing",
            css: "done"
          },
          {
            name: "measuring",
            css: "done"
          },
          {
            name: "processing",
            css: "done"
          },
          {
            name: "engineering",
            css: "done"
          },
          {
            name: "finishing",
            css: "done"
          },
          {
            name: "finished",
            css: "done"
          }
        ];
      } else if (this.type == "coring") {
        this.tempSteps = [
          {
            name: "awaiting_validation",
            css: "done"
          },
          {
            name: "programing_validated",
            css: "done"
          },
          {
            name: "taken",
            css: "done"
          },
          {
            name: "taking_validated",
            css: "done"
          },
          {
            name: "sent_for_analysis",
            css: "done"
          },
          {
            name: "results_obtained",
            css: "done"
          }
        ];
      }
      let stepTriggered = false;

      this.steps = this.tempSteps.map(step => {
        if (
          this.statusForm === "finished" ||
          this.statusForm === "results_obtained"
        ) {
          step.css = "allDone";
        } else if (this.statusForm === step.name) {
          step.css = "in_progress";
          stepTriggered = true;
        } else if (stepTriggered === true) {
          step.css = "waiting";
        } else {
          step.css = "done";
        }

        step["nameTranslated"] = this.$t(step.name);

        return step;
      });
    },
    editModeFalse() {
      this.$emit("cancelEdit");
    }
  },
  watch: {
    currentStep() {
      this.loadSteps();
    }
  },
  mounted() {
    this.loadSteps();
  }
};
</script>

<style lang="scss" scoped>
.stepbar {
  display: inline-block;

  .step {
    height: 15px;
    width: 50px;
    margin-right: 5px;
    border-radius: 5px;
  }

  .waiting {
    background-color: #ebebeb;
  }

  .in_progress {
    background-color: #ed7507;
  }

  .done {
    background-color: #ff9c42;
  }

  .allDone {
    background-color: #2a7221;
  }
}

.step_text {
  font-size: 16px;
}
</style>
