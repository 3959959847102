<template>
	<section
	class="coringCopyPopIn tw-z-[1]"
	@click="$event.stopPropagation()"
	>
		<v-card
		flat
		outlined
		class="coringCopyPopIn__card"
		>
			<v-card-title
			class="center justify-center tw-mb-[10px]"
			align-items
			>
				<h3>Annuler la prestation de la campagne</h3>
			</v-card-title>

			<v-card-subtitle
			v-if="campaign.provider === 'AREIA'"
			align-items
			class="tw-mt-[10px] tw-flex justify-center"
			>
				<p>
					Vous devrez impérativement supprimer la ligne de commande de la plateforme AREIA avant de réaliser un nouvel envoi.<br/>
					Si la commande n'est pas annulée, le prochain envoi <strong>ne fonctionnera pas !</strong>
				</p>
			</v-card-subtitle>

			<v-card-subtitle>
				<table class="tw-table-auto tw-border tw-border-gray-300 tw-w-full tw-mx-auto tw-text-left">
					<thead>
						<tr>
							<th class="tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-text-center">
								Numéro de l'analyse
							</th>
							<th class="tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-text-center">
								Carottes et couches analysées
							</th>
							<th
							class="tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-text-center"
							>
								Actions
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
						v-for="data in formattedData"
						:key="data.campaignAnalyseNumber"
						class="tw-border tw-border-gray-300"
						>
							<td class="tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-text-center">
								Analyse numéro <span style="color: black">
									{{ data.campaignAnalyseNumber }}
								</span> <br/> 
								Prestataire <span style="color: black">
									{{ data.campaignProvider }}
								</span> <br/> 
								Commande <span style="color: black">
									{{ data.campaignOrderNumber }}
								</span> <br/> 
								Réf. Nextroad <span style="color: black">
									{{ data.campaignNextroadRef }}
								</span> <br/> 
								Réf. client <span style="color: black">
									{{ data.campaignCustomerOrderRef }}
								</span>
								<span v-if="data.campaignOrderStatus !== null">Statut <span style="color: black">
									{{ data.campaignOrderStatus }}
								</span></span> <br/>
								<span>Date d'envoie <span style="color: black">
									{{ moment(data.campaignAnalyseSendDate).format("DD/MM/YYYY") }}
								</span></span>
							</td>
							<td class="tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-block tw-max-h-[300px] tw-overflow-y-auto tw-h-auto tw-min-h-[50px]">
								<ul class="tw-list-disc tw-ml-4">
									<li
									v-for="core in data.cores"
									:key="core.coreNumber +'-'+ data.campaignOrderNumber"
									class="tw-mb-2"
									>
										<strong>Carotte {{ core.coreNumber }}:</strong>
										<ul class="tw-list-disc tw-ml-6 tw-mt-1">
											<li
											v-for="layer in core.layers"
											:key="layer.layerNumber"
											class="tw-ml-4"
											>
												Couche: {{ layer.layerNumber }} - Prestations : 
												<ul class="tw-list-disc tw-ml-6 tw-mt-1">
													<li
													v-for="prestation in layer.prestations"
													:key="prestation"
													>
														{{ prestation }}
													</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</td>

							<td>
								<ButtonSlot
								@click="refreshData(data.campaignAnalyseId)"
								>
									Rafraichir les données
								</ButtonSlot>
								<ButtonSlot
								v-if="campaign.status !== 'results_obtained'"
								@click="cancelPrestation(data.campaignAnalyseId)"
								>
									Supprimer
								</ButtonSlot>
							</td>
						</tr>
					</tbody>
				</table>
			</v-card-subtitle>

			<v-card-actions>
				<MediumButtonSlot @click="closePopin">
					Fermer
				</MediumButtonSlot>
				<v-spacer/>
			</v-card-actions>
		</v-card>
	</section>
</template>

<script>
import {mapActions} from "vuex";
export default {
	name: "CancelPrestationCampaignModal",
	props: {
		campaign: {
			default: false
		}
	},
	data(){
		return {
			title: ""
		};
	},
	computed: {
		analyses(){
			const analyseIds = new Set();

			// Parcourir chaque core et ajouter les campaignAnalyseId dans le Set
			this.campaign.cores.forEach(core => {
				if(core.campaignAnalyse){
					analyseIds.add(core.campaignAnalyse.id);
				}

				// Parcourir les layers de chaque core et ajouter les campaignAnalyseId des layerPrestations
				core.layersAndInterfaces
				.filter(layer => layer.type === "layer")
				.forEach(layer => {
					layer.layerPrestations.forEach(prestation => {
						if(prestation.campaignAnalyse){
							analyseIds.add(prestation.campaignAnalyse.id);
						}
					});
				});
			});

			return analyseIds;
		},
		formattedData(){
			const analyseIds = this.analyses;
			const campaignProvider = this.campaign.provider;
			let groupedData = [];

			analyseIds.forEach(analyseId => {
				this.campaign.cores.forEach(core => {
					const coreCampaignAnalyseId = core.campaignAnalyse ? core.campaignAnalyse.id : null;

					const coreDisplayName = core.displayNamePrefix?.name + core.displayName;

					if(coreCampaignAnalyseId === analyseId){
						if(!groupedData[coreCampaignAnalyseId]){
							const campaignAnalyse = core.campaignAnalyse;
							groupedData[coreCampaignAnalyseId] = {
								campaignAnalyseId: coreCampaignAnalyseId,
								campaignAnalyseNumber: campaignAnalyse.number,
								campaignOrderNumber: campaignProvider === "AREIA" ? campaignAnalyse.businessId : campaignAnalyse.orderNumber,
								campaignProvider: campaignProvider,
								campaignCustomerOrderRef: campaignAnalyse.campaignCustomerOrderRef ?? this.campaign.customerOrderRef,
								campaignNextroadRef: campaignAnalyse.campaignNextroadRef ?? this.campaign.nextroadRef,
								campaignOrderStatus: campaignAnalyse.orderStatus,
								campaignAnalyseSendDate: campaignAnalyse.createdAt,
								cores: []
							};
						}

						let coreEntry = groupedData[coreCampaignAnalyseId].cores.find(c => c.coreNumber == coreDisplayName);

						if(!coreEntry){
							coreEntry = {
								coreNumber: core.displayName !== "000" ? coreDisplayName.length > 6 ? coreDisplayName.substr(coreDisplayName.length - 6) : coreDisplayName : core.number,
								layers: []
							};
							groupedData[coreCampaignAnalyseId].cores.push(coreEntry);
						}
						core.layersAndInterfaces.filter(layer => layer.type === "layer").forEach(layer => {
							let existingLayer = coreEntry.layers.find(l => 
								l.layerNumber === layer.number
							);
							if(!existingLayer){
								coreEntry.layers.push({
									layerNumber: layer.number,
									prestations: layer.layerPrestations.filter(p => p.campaignAnalyse?.id === coreCampaignAnalyseId).map(p => this.$t(p.prestation.name))
								});
							}
						});
					}

					core.layersAndInterfaces.filter(layer => layer.type === "layer").forEach(layer => {
						layer.layerPrestations.forEach(prestation => {
							const prestationCampaignAnalyseId = prestation.campaignAnalyse ? prestation.campaignAnalyse.id : null;

							if(prestationCampaignAnalyseId === analyseId){
								if(!groupedData[prestationCampaignAnalyseId]){
									const prestationCampaignAnalyse = prestation.campaignAnalyse;
									groupedData[prestationCampaignAnalyseId] = {
										campaignAnalyseId: prestationCampaignAnalyseId,
										campaignAnalyseNumber: prestationCampaignAnalyse.number,
										campaignOrderNumber: campaignProvider === "AREIA" ? prestationCampaignAnalyse.businessId : prestationCampaignAnalyse.orderNumber,
										campaignProvider: campaignProvider,
										campaignCustomerOrderRef: prestationCampaignAnalyse.campaignCustomerOrderRef ?? this.campaign.customerOrderRef,
										campaignNextroadRef: prestation.campaignAnalyse.campaignNextroadRef ?? this.campaign.nextroadRef,
										campaignOrderStatus: prestation.campaignAnalyse.orderStatus,
										cores: []
									};
								}

								let newCoreEntry = groupedData[prestationCampaignAnalyseId].cores.find(c => c.coreNumber === coreDisplayName);
								if(!newCoreEntry){
									newCoreEntry = {
										coreNumber: core.displayName !== "000" ? coreDisplayName.length > 6 ? coreDisplayName.substr(coreDisplayName.length - 6) : coreDisplayName : core.number,
										layers: []
									};
									groupedData[prestationCampaignAnalyseId].cores.push(newCoreEntry);
								}

								let existingLayer = newCoreEntry.layers.find(l => l.layerNumber === layer.number);
								if(!existingLayer){
									newCoreEntry.layers.push({
										layerNumber: layer.number,
										prestations: layer.layerPrestations.filter(p => p.campaignAnalyse?.id === prestationCampaignAnalyseId).map(p => this.$t(p.prestation.name))
									});
								}
							}
						});
					});
				});
			});

			// Transformer l'objet en tableau pour l'affichage
			return Object.values(groupedData);
		}
	},
	methods: {
		...mapActions("workspace", ["updateWorkspaces"]),
		...mapActions("project", ["updateCampaigns"]),

		closePopin(){
			this.$emit("closeModale");
		},

		async cancelPrestation(analyseId){
			if(this.analyses.size === 1){
				await this.$api.coringCampaigns.editStatus(this.campaign.id, "taking_validated");
			}
			if(this.campaign.provider === "EUROFINS" || this.campaign.provider === "EUROFINS - Spécifique DIR EST"){
				await this.$api.campaignAnalyse.deleteEurofinsPrestation(analyseId);
			}
			else {
				await this.$api.campaignAnalyse.backFromSentPrestation(analyseId);
			}
			this.$emit("validateModale");
		},
		async refreshData(analyseId){
			await this.$api.coringCampaigns.getFromProvider(this.campaign.id, false, analyseId);
		}
	},
	mounted(){
		this.$root.$on("closeModale", this.closePopin);
	}
};

</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";
.coringCopyPopIn {
  @extend %popinView;

  .table {
    th {
      text-align: center !important;
    }
  }

  .v-card {
    width: 60%;
    text-align: center;
    padding: 20px 20px;
    max-height: 90%;
    overflow: auto;
  }

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .v-input--selection-controls {
	margin-top: 0px !important;
  }

  // Styles personnalisés pour le tableau
  table {
    width: 100%;
    border-collapse: collapse;
    margin: auto;
    text-align: left;
    
    th, td {
      border: 1px solid #ccc; // Couleur de bordure
      padding: 10px;
    }
    
    th {
      background-color: #f9f9f9; // Couleur de fond pour les en-têtes
      text-align: center;
    }
  }
}
</style>
